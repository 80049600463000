
// Tabs
.nav-pills {

    .nav-item + .nav-item {
        margin-left: 0.75rem;

        @include media-breakpoint-up(lg) {
            margin-left: 1rem;
        }
    }

    .nav-link {
        position: relative;
        display: inline-block;
        font-size: $font-size-xs;
        font-weight: $font-weight-bold;
        padding: 0 0 0.125rem;
        z-index: 0; // in fixed tabs border is not visible if there is no z-index

        @include media-breakpoint-up(xl) {
            font-size: $font-size-sm;
            padding: 0 0 0.25rem
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 0;
            height: 3px;
            border-radius: 2px;
            background-color: $gray-300;
            z-index: -1;
            @include transition(background-color 0.25s ease-in-out);
        }

        &:focus {
            outline: none;
            &::after {
                background-color: $btn-focus-color;
            }
        }

    }


    .nav-link.active,
    .nav-link.mixitup-control-active,
    .show > .nav-link {
        &::after {
            background-color: $primary;
        }
    }

    &.scrollable-tabs {

        @include media-breakpoint-down(sm) {
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;

            .nav-link {
                white-space: nowrap;
            }
        }

    }
}

/* bootstrap hack: fix content width inside hidden tabs to enable eg. slider inside tabs*/
.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: block;     /* undo display:none          */
    height: 0;          /* height:0 is also invisible */
    overflow-y: hidden; /* no-overflow                */
}
.tab-content > .active,
.pill-content > .active {
    height: auto;       /* let the content decide it  */
} /* bootstrap hack end */

