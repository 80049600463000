// Cards
@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Bootstrap card styles

//
// Base styles
//

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    height: $card-height;
    word-wrap: break-word;
    background-color: $card-bg;
    background-clip: border-box;
    box-shadow: $card-shadow;
    font-size: $font-size-xs;

    @include border-radius($card-border-radius);

    @include media-breakpoint-up(xl) {
        font-size: $font-size-sm;
    }

    > hr {
        margin-right: 0;
        margin-left: 0;
    }
}

.card-body {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    // Workaround for the image size bug in IE
    // See: https://github.com/twbs/bootstrap/pull/28855
    min-height: 1px;
    padding-top: $card-spacer-top;
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;
    padding-bottom: $card-spacer-y;

    @include media-breakpoint-up(xl) {
        padding-top: $card-spacer-top-xl;
        padding-left: $card-spacer-x-xl;
        padding-right: $card-spacer-x-xl;
        padding-bottom: $card-spacer-y-xl;
    }

    .card.has-icons-bottom & {
        padding-bottom: $card-spacer-top;

        @include media-breakpoint-up(lg) {
            padding-top: 2rem;
            padding-bottom: 3.25rem;
        }
    }
}

.card-title {

    margin-bottom: $spacer * 0.75;
    line-height: 1.26315;

    &.h2 {
        margin-bottom: $spacer*1.5;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: $spacer;
    }
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link {
    @include hover() {
        text-decoration: none;
    }

    + .card-link {
        margin-left: $card-spacer-x;
    }
}

//
// Optional textual caps
//

.card-header {
    padding: $card-spacer-y $card-spacer-x;
    margin-bottom: 0; // Removes the default margin-bottom of <hN>
    color: $card-cap-color;
    background-color: $card-cap-bg;
    border-bottom: $card-border-width solid $card-border-color;

    &:first-child {
        @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
    }
}

.card-footer {
    padding: 0 $card-spacer-x $card-spacer-y $card-spacer-x;

    @include media-breakpoint-up(xl) {
        padding: 0 $card-spacer-x-xl $card-spacer-y-xl $card-spacer-x-xl;
    }

    &.d-lg-none {
        /* only more link on small screens */
        @include media-breakpoint-down(md) {
            margin-top: -1rem;
        }
    }
}


.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0; // For IE: https://github.com/twbs/bootstrap/issues/29396
    width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
    height: auto;
}

.card-img,
.card-img-top {
    @include border-top-radius($card-inner-border-radius);
}

.card-img,
.card-img-bottom {
    @include border-bottom-radius($card-inner-border-radius);
}

.card {
    .card-icons-wrapper {
        position: absolute;
        left: $card-spacer-x;
        z-index: 1;

        @include media-breakpoint-up(xl) {
            left: $card-spacer-x-xl;
        }

        svg {
            display: inline-block;

            & + svg {
                margin-left: 0.25rem;

                @include media-breakpoint-up(lg) {
                    margin-left: 0.5rem;
                }
            }
        }

        &.icons-top {
            top: -22px;

            @include media-breakpoint-up(xl) {
                top: -30px;
            }

            > svg {
                @include media-breakpoint-down(lg) {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        &.icons-bottom {
            bottom: -22px;
        }
    }

    &.swatch-dark {
        .card-icon-wrapper.icons-bottom {
            bottom: -20px;
        }
    }
}

// Bootsrap card styles end

//
// card-group
//
.frame-type-wvier_cardgroup {
    .frame-header {
        margin-bottom: 2.625rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 6rem;
        }
    }
}

//
// card
//
.card-img-top {
    overflow: hidden;
    picture,
    img {
        width: 100%;
        height: auto;
    }
}
.card-body {
    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}

.card-logo {
    min-height: 46px;
    display: flex;
    align-items: center;

    picture {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        min-height: 56px;
    }
}

// Card Link
a.card {
    position: relative;
    @include transition(box-shadow 0.25s ease-in-out);

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border: 2px solid $primary;
        border-radius: $card-border-radius;
        opacity: 0;
        @include transition(opacity 0.25s ease-in-out, border-color 0.25s ease-in-out);

    }

    @include hover() {
        box-shadow: $box-shadow-hover;

        &::after {
            opacity: 1;
        }
    }

    &:focus {
        &::after {
            opacity: 1;
            border-color: $btn-focus-color;
        }
    }

    @include media-breakpoint-up(lg) {
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translate(22px, 22px);
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALBAMAAABbgmoVAAAAIVBMVEUAAAA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TCNSn13AAAACnRSTlMA0ejh1MdlUSseul03KwAAABpJREFUCNdjIAdwChWAKNZVC0AUC4TiEE4AACx4A6S3SUMDAAAAAElFTkSuQmCC');
            background-position: bottom right;
            z-index: -1;
            opacity: 0;
            @include transition(opacity 0.25s ease-in-out);
        }

        @include hover() {
            &::before {
                opacity: 1;
            }
        }
    }
}

//
// Card Group Element
//
.card-group-element {
    margin: -$card-deck-margin;
    margin-bottom: -$card-deck-margin;
    margin-top: 0.938rem;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        margin: -$card-deck-margin-lg;
        margin-bottom: -$card-deck-margin-lg;
    }
}
.card-group-element-item {
    width: 100%;
    padding: $card-deck-margin;

    @include media-breakpoint-up(lg) {
        padding: $card-deck-margin-lg;
    }

    .card {
        height: 100%;

        ul, ol {
            margin-top: 1.5rem;
            padding-left: 1.5rem;

            @include media-breakpoint-up(xl) {
                margin-top: 2.625rem;
                padding-left: 2.25rem;
            }
        }

        ul {
            list-style: none;
            list-style-image: none;
            list-style-type: none;

            li {
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 1px;
                    left: -1.5rem;
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqBAMAAAA37dRoAAAALVBMVEUAAAA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA9XD/eAAAADnRSTlMA758gYL9AMM8QcIBQ39qRqPwAAAFGSURBVCjPhZO/SgNBEMZHExURxJBCRDjS2C4RFKwkILaHqKWItUWIlYWl/aEvYWEh+iD6BCLeGsMh+j2DO3O37O4tl0yxN3z3u7mdf1TZzhaA4wMK7BClnfriKysdPt6duAsUD+Z5mQEnVlxMsNkXr/WC/KJSU+i7yl1WGJdeW9677wbiDHFLzvYwkVhJId/bGFnO/1hAl3zbxpE53yBRXWT8mvNDU2jqj2+wUVPvMTBhn33pZkQrJvA++p64lHSphS9KCx+94tSyMamfAEWP6EnT+SRANSf7Tfiso7QKUUNU1LU6SnOl2j5zqKgSYZiPPNTGTbHuUFblZvMwsEXlZpKFMrBFOQvOWOBri3LGXB2BOxbl6nAlBYZFuZK26graq7p0SOCe65Dr5qPXzYbON01JPFGN0xdP6rSpjjcg3pYZmxVv4T/7LsiwD1L8YwAAAABJRU5ErkJggg==');
                    background-size: contain;
                    background-repeat: no-repeat;

                    @include media-breakpoint-up(xl) {
                        width: 21px;
                        height: 21px;
                        top: 2px;
                        left: -2.25rem;
                    }
                }

            }
        }

        b, strong {
            color: $primary;
        }
    }

    &.has-icon-top {
        margin-top: 1rem;
    }

}

.card-group-element-columns-2 {
    .card-group-element-item {
        @include media-breakpoint-up('sm') {
            width: percentage(6/12);
        }
    }
}

.card-group-element-columns-3 {
    .card-group-element-item {
        @include media-breakpoint-up('md') {
            width: percentage(4/12);
        }
    }
}

.card-group-element-columns-4 {
    .card-group-element-item {

        @include media-breakpoint-up('sm') {
            width: percentage(6/12);
            margin-bottom: 2rem;
        }
        @include media-breakpoint-up('lg') {
            width: percentage(3/12);
            margin-bottom: 4.5rem;
        }
    }

    &.product-card-group {
        .card-group-element-item {
            @include media-breakpoint-up('lg') {
                width: percentage(3/12) !important;
            }
        }
    }
}

.product-card-group {
    @include media-breakpoint-between(lg, lg) {
        overflow: hidden; //card hover effect
        .card {
            h4, .h4 {
                font-size: $h4-font-size;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &.splide__list {
            /* has to be important to override splide styles :/ */
            margin: -$grid-gutter-width-lg/2 !important;
            padding-bottom: 7rem !important;
        }

        .card-group-element-item {
            margin-bottom: 3rem;

            &:first-child,
            &:nth-child(5),
            &:nth-child(9) {
                transform: translateY(9rem);
            }

            &:nth-child(2),
            &:nth-child(6),
            &:nth-child(10) {
                transform: translateY(6rem);
            }

            &:nth-child(3),
            &:nth-child(7),
            &:nth-child(11) {
                transform: translateY(3rem);
            }
        }
    }
}

.product-splide  {
    @include media-breakpoint-down(md) {
        margin-left: -$grid-gutter-width;
        margin-right: -$grid-gutter-width;

        .card-group-element {
            flex-wrap: nowrap;
        }
    }

    @include media-breakpoint-up(lg) {
        .splide {
            &__track {
                overflow: visible;
                z-index: auto;
            }

            &__list {
                width: auto;
                display: flex !important;
            }

            &__pagination {
                display: none;
            }
        }
    }
}

//card inside slide should always have the same height
.splide__slide {
    > .card {
        height: 100%;
    }
}
//case studies
.card.case-study-card {
    .card-body {
        .card-lead-text {
            font-size: $font-size-sm;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 3rem;
            padding-right: 3rem;

            .card-lead-text {
                font-size: 1.5rem;
            }
        }
    }

    &.bgimage-globe {
        overflow: hidden;
        &::after {
            content: "";
            position: absolute;
            top: -18px;
            right: -120px;
            width: 420px;
            height: 420px;
            max-width: 100%;
            background-image: $globe-bg-green;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 0;
        }
    }

    .card-icons-wrapper {
        left: 1.5rem;
        @include media-breakpoint-up(lg) {
            left: 3rem;
        }
    }
}

.card-splide {
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;

    .splide__slide {
        margin: $grid-gutter-width $grid-gutter-width/2 $grid-gutter-width*1.5;
    }

    @include media-breakpoint-up(lg) {
        margin-left: -$grid-gutter-width-lg / 2;
        margin-right: -$grid-gutter-width-lg / 2;

        .splide__slide {
            margin: $grid-gutter-width-lg $grid-gutter-width-lg/2 $grid-gutter-width*1.5;
        }
    }

    /*@media (min-width: 1248px) {
        margin-left: -$grid-gutter-width-lg;
        margin-right: -$grid-gutter-width-lg;
    }

    .card {
        height: 100%;
    }*/
}

/* News */
.card.news-card {
    .card-body {
        padding-top: 1.5rem;

        @include media-breakpoint-up(xl) {
            padding-top: 1.5rem;
            padding-bottom: 2rem;
        }
    }
}

/* uploads */
.download-cards {
    margin-bottom: 2rem;

    .card.download {
        height: 100%;

        .card-img-top {
            height: 200px;

            @include media-breakpoint-up(md) {
                height: 150px;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .card-body {
            padding-top: 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include media-breakpoint-up(xl) {
                padding-top: 1.5rem;
                padding-bottom: 2rem;
            }

            .link-primary {
                position: relative;

                .linktext {
                    margin-left: 30px;
                }

                &::after {
                    position: absolute;
                    top: 4px;
                    width: 19px;
                    height: 19px;
                    background-image: escape-svg($link-primary-download-icon);
                    margin: 0;
                    left: 0;
                }

                &:hover {
                    &:after {
                        transform: translateY(-5px) translateX(0);
                    }
                }
            }
        }
    }
}

.popupcards {
    .card {
        .card-icons-wrapper.icons-top {
            top: -20px;
            left: auto;
            right: 20px;

            svg circle {
                fill:$green-2;
            }
        }

        &:hover {
            .card-icons-wrapper.icons-top {
                svg circle {
                    fill: $green;
                }
            }
        }
    }
}

