@mixin font-awesome-900 {
    font-family: "Font Awesome 5 Pro", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

@mixin make-swatch($name, $settings) {
    $background: map-get($settings, 'background');
    $color: map-get($settings, 'color');
    .swatch-#{$name} {
        color: $color;
        background: $background;
    }
}

@mixin btn-base {
    display: inline-block;
    font-weight: $btn-font-weight;
    letter-spacing: $btn-font-spacing;
    color: $body-color;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    padding: $btn-padding-t $btn-padding-x $btn-padding-b;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    border-radius: $btn-border-radius;
    @include transition($btn-transition);

    @include media-breakpoint-up(lg) {
        padding: $btn-padding-t-lg $btn-padding-x-lg $btn-padding-b-lg;
    }

    @include hover() {
        color: $body-color;
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        pointer-events: none;
        @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
}

@mixin btn-primary {
    color: $white;
    background-color: $primary;
    border-color: $primary;

    @include hover() {
        color: $white;
        background-color: $btn-focus-color;
        border-color: $btn-focus-color;
    }

    &:not(:disabled):not(.disabled) {
        &:active,
        &.active {
            color: $white;
            background-color: darken($primary, 10%);
            border-color: darken($primary, 10%);
        }
    }
}

@mixin custom-form-validation-state($state, $color, $icon) {
    /*.#{$state}-feedback {
        display: none;
        width: 100%;
        margin-top: $form-feedback-margin-top;
        @include font-size($form-feedback-font-size);
        color: $color;
    }

    @include form-validation-state-selector($state) {
        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
            display: block;
        }
    }*/

    .form-control {
        @include form-validation-state-selector($state) {
            padding-right: $input-padding-x * 2;
            background-image: escape-svg($icon);
            background-repeat: no-repeat;
            background-position: right $input-padding-x center;
            background-size: 1rem 1rem;

            @include media-breakpoint-up(lg) {
                padding-right: $input-padding-x-lg * 2;
                background-position: right $input-padding-x-lg center;
            }
        }
    }

    // stylelint-disable-next-line selector-no-qualifying-type
    textarea.form-control {
        @include form-validation-state-selector($state) {
            padding-right: $input-padding-x * 2;
            background-position: top $input-padding-top right $input-padding-x;

            @include media-breakpoint-up(lg) {
                padding-right: $input-padding-x-lg * 2;
                background-position: top $input-padding-top-lg right $input-padding-x-lg;
            }
        }
    }

    .custom-select { 
        @include form-validation-state-selector($state) {
            padding-right: $custom-select-feedback-icon-padding-right;
            background: $custom-select-background, escape-svg($icon) $input-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
        }
    }

    .custom-control-input {
        @include form-validation-state-selector($state) {
            //show only invalid state here.. green checkboxes are ugly..
            @if $state == "invalid" {
                ~ .custom-control-label {
                    color: $color;
                }
            }


/*            &:checked {
                ~ .custom-control-label::before {
                    border-color: lighten($color, 10%);
                    @include gradient-bg(lighten($color, 10%));
                }
            }

            &:focus {
                ~ .custom-control-label::before {
                    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                }

                &:not(:checked) ~ .custom-control-label::before {
                    border-color: $color;
                }
            }*/
        }
    }
}
