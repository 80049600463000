@import "Libraries/Scss/magnific-popup";

.mfp-arrow {
    font-size: 2.5rem;
    color: $white;
}

.mfp-arrow:before,
.mfp-arrow:after {
    content: none;
}
