// stylelint-disable declaration-no-important, selector-list-comma-newline-after


h3, .h3 {
    @include font-size($h3-font-size);
    font-weight: $font-weight-semibold;
    line-height: 1.4166667;

    @include media-breakpoint-up(lg) {
        @include font-size($h3-font-size-lg);
    }
}

h4, .h4 {
    @include font-size($h4-font-size);
    font-weight: $font-weight-semibold;

    @include media-breakpoint-up(lg) {
        @include font-size($h4-font-size-lg);
    }
}

h5, .h5, h6, .h6 {
    @include font-size($h5-font-size);
    font-weight: $font-weight-semibold;
}

//
// Horizontal rules
//

hr {
    margin-top: $hr-margin-y;
    margin-bottom: $hr-margin-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
    @include font-size($small-font-size);
    font-weight: $font-weight-normal;
}

//
// Lists
//

.list-unstyled {
    @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
    @include list-unstyled();
}
.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: $list-inline-padding;
    }
}


//
// Misc
//

// Blockquotes
.blockquote,
blockquote {
    font-family: $font-family-headings;
    line-height: 1.13043;
    margin-bottom: $spacer;
    position: relative;
    @include font-size($blockquote-font-size);

    @include media-breakpoint-up(lg) {
        margin-bottom: $spacer * 1.5;
        @include font-size($blockquote-font-size-lg);
    }

    &::before {
        content: "";
        display: inline-block;
        width: 84px;
        height: 38px;
        margin-bottom: -4px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAA4CAMAAAAICWz2AAAAilBMVEUAAAA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TC+GiA6AAAALXRSTlMABS8TDJok02sPw1JIYN+3OglBNKyiVqfp2Mq+XT2JdrNNGpQpHoLOjzbz5WSw5wKPAAADe0lEQVRYw+1Ya6+aQBTcRd4IAvJGERSLr/P//17ZObQpbULb25s2zWW+jBNNZs+Jk4EVRmGLEWFQnUeSafFSMn66GyWTIlNy+6ydkaL26YkFeM9dNJJTP7dKZkUiR9q4z1jJV5FCVkGopF0YgqhQHw2iTn1DFCiZEx1HOhNZSu6ItJFMInfJ3CUyR9KIdkpaRGqgI1GuZEC0UYMQGUoWROLkYpi+TnTFn1wcWqtTNYNsXBx6XxtSyYsbLpmH7gU/M+o91uc2SkZprWF97idFel73WJN7Eis+HjR7A7Yz0N52FEm7gwxtneUW8jjKBUR2DN7aUpFuh5AdS8feQ2Y2aGNrYkrPibPlTClpiDROXqLkhTN0JUqXzA2iKyfyomTC2dKImimvjpqH6DTlUhTUYnAidS79Qfl0lp7NDQRQmSP2tyXzG9GZzREqg837yS2nh652S4TRWyrE2Yt4NyHo6kmwdweZnmCpgXolF+D1IM2bpAm6s5TeFRx2oMg7ixUfD7fghXyX3KFGcECgS3SoTAMPFVG2+khRUm7FArZBEqnItCXy7gWpRMOWRyUPgcENWyLvr+D2byv105AhHVXrKG4GD4uoEh0dWmHUsMoj7KWKl8zjyuAOrUIsokLD6kmFUb2hUeS0FXKbDZ/Eio8H03fAhy2o93WWMUjzI7B/BO19KRYg/Tv4yD+LfA0yPoD0Qw/esnR8Uzyonmos5Eptuca4UqeU3L5WqvFrlXrjvHKlTiXXcqXep5Kr6TGa7yZzdWqdKJmbpwjgV/PLkvnlqzlClc7NEyKuVDbfjeamr8/XHs3XLsF+OK1dLMLfg0IfJKe1H1lG/mzt+rj2FR8PjXVQpA07dOjNQs/cB3SoNKwOfxkrgUyt5WKxUm6S4ahkZxmQ7YD4Z9YNDbsbNPylrebfVmpT8uRWvUGHlpg8tHgRadnxSAkqNbeWHyasXPIzR4zJy5RHtUJMXqJhN7XFk5eNWLFijj6T4OwO0jLxdsjMBMcZSM+uYgE/tNoZrfZWJETOrNUKsYCFPn8LWq7U8Js+X8bejvhyIIYMbfl2c93WwN0L5Ni9WLFihi/vavcqjcSfoqsagYZ1NfELmFfqn2JeqT+HzAO+8g52+h9az9/P/z6kWPEfwPTEewE3kL8D3L2+F1J66L9pnrybOW6dfwfhyXk3c+f0fb4/AyzVXCAq+XbrAAAAAElFTkSuQmCC');
        background-repeat: no-repeat;
        background-size: contain;

        @include media-breakpoint-up(lg) {
            width: 124px;
            height: 56px;
            margin-left: -24px;
        }
    }

    > p:last-child::after {
        content: "\201C";
        display: inline;
    }
}


