@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$colors: map-remove($colors, "blue", "indigo", "purple", "pink", "red", "orange", "yellow", "teal", "cyan");
$colors: (
        "gray": $gray-400
);

$theme-colors: map-remove($theme-colors, "info", "warning");

//Bootstrap Optional
//@import "bootstrap/scss/type";
//@import "bootstrap/scss/images" --> custom;
//@import "bootstrap/scss/code";
//@import "bootstrap/scss/grid"; --> custom;
//@import "bootstrap/scss/tables"; --> custom
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/button-group";
//@import "bootstrap/scss/input-group";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/jumbotron";
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/media";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/utilities"; --> custom
@import "bootstrap/scss/print";

// Custom Styles
@import "mixins";

@import "dpcookieconsent";
@import "grid";
@import "utilities";
@import "breadcrumb";
@import "alert";
@import "type";
@import "cards";
@import "cta";
@import "slider";
@import "magnific";
@import "modal";
@import "table";
@import "tabs";
@import "casestudies";

[tabindex]:not([tabindex="-1"]):focus,
a:focus,
button:focus,
input:focus,
select:focus:focus,
textarea:focus {
    outline-color: currentColor;
    outline-offset: 2px;
}

.w-100 {
    width: 100% !important;
}

@include media-breakpoint-up(md) {
    .w-md-50 {
        width: 50% !important;
    }
}

@include media-breakpoint-up(lg) {
    .container,
    .container-fluid {
        padding-right: $grid-gutter-width-lg / 2;
        padding-left: $grid-gutter-width-lg / 2;
    }

    .row:not(.no-gutters) {
        margin-right: -$grid-gutter-width-lg / 2;
        margin-left: -$grid-gutter-width-lg / 2;
    }

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        padding-right: $grid-gutter-width-lg / 2;
        padding-left: $grid-gutter-width-lg / 2;
    }

    .w-lg-50 {
        width: 50% !important;
    }
}

@each $name, $settings in $swatch-colors {
    @include make-swatch($name, $settings);
}

a {
    @include transition($link-transition);
}

//
// Figures
// "bootstrap/scss/images"
//

.figure {
    // Ensures the caption's text aligns with the image.
    display: inline-block;
}

.figure-img {
    margin-bottom: $spacer / 2;
    line-height: 1;
}

.figure-caption {
    @include font-size($figure-caption-font-size);
    color: $figure-caption-color;
}

.img-rounded {
    border-radius: 50%;
}

.frame-type {
    &-text,
    &-textpic,
    &-textmedia,
    &-wvier_hero,
    &-wvier_accordion {
        a:not(.btn):not(.link-primary):not(.link-primary-back):not(.img-link):not(.link-external) {
            position: relative;
            display: inline;
            text-decoration: none;
            background-image: linear-gradient($primary, $primary);
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 3px;
            padding-bottom: 4px;

            @include hover {
                color: $primary;
            }

            html.msie-11 & {
                padding-bottom: 0;
            }
        }

        ul, ol {
            padding-left: 1em;
        }

        ul {
            list-style: none;
            list-style-image: none;
            list-style-type: none;

            li {
                position: relative;

                &::before {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    left: -1em;
                    top: 0.5263158em;
                    width: 6px;
                    height: 6px;
                    background-color: $primary;
                    border-radius: 1rem;
                }

                + li {
                    margin-top: 0.5rem;

                    @include media-breakpoint-up(lg) {
                        margin-top: 0.75rem;
                    }
                }

            }
        }

        .btn-primary {
            margin-top: 0.5rem
        }
    }

    @include media-breakpoint-up(lg) {
        &-wvier_hero {
            .btn-primary {
                margin-top: 1rem;
            }
        }
    }
}


.img-grayscale {
    filter: grayscale(100%);
}

.text-style-primary {
    color: $primary;
    font-family: "Soehne Breit", sans-serif;
    font-size: $font-size-sm;

    @include media-breakpoint-up(lg) {
        &.font-lg {
            font-size: $font-size-base;
        }
    }
}

// Headsup Header
@include media-breakpoint-down(md) {
    .navbar.fixed-top {
        transform: none;
        /*in critical css with other transitions: transition: .25s transform;*/
        backface-visibility: hidden;

        &.heads-up:not(.navbar-open) {
            transform: translateY(-55px);
            transform: translate3d(0, -55px, 0);
        }
    }
}

// Skiplinks
.skiplinks {
    .sr-only {
        &:focus {
            display: inline-block;
            padding: 0.5rem;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            background-color: $white;
            z-index: $zindex-tooltip;
        }
    }
}

.close {
    color: $dark;
    opacity: 0.85;
    line-height: 1;
    font-size: 1.625rem;
    width: 1.625rem;
    overflow: hidden;

    // Override <a>'s hover style
    @include hover() {
        color: $dark;
        text-decoration: none;
    }

    &:not(:disabled):not(.disabled) {
        @include hover-focus() {
            opacity: 1;
        }
    }
}

// stylelint-disable-next-line selector-no-qualifying-type
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

// stylelint-disable-next-line selector-no-qualifying-type
a.close.disabled {
    pointer-events: none;
}

// Layout
.container,
.container-fluid {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

// Navbar
html.main-navbar-open {
    overflow: hidden;
}

@include media-breakpoint-down(md) {
    .navbar-expand-lg {
        .navbar-collapse {
            position: fixed;
            z-index: $zindex-fixed;
            top: 55px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 1.5rem 1rem;
            overflow-y: auto;
            background-color: $white;
        }

        .navbar-nav {
            .nav-link {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .fa-chevron-down {
                    transform: translate(0, -50%) rotate(-90deg);
                }

                &.nav-link-icon {
                    justify-content: flex-start;

                    .fa-globe,
                    .fa-user-circle {
                        position: relative;
                        top: -1px;
                        margin-right: 0.5rem;
                    }
                }
            }

            .nav-item-icon.border-top {
                margin-top: 1.5rem;
                padding-top: 1.5rem;
                border-top: 2px solid $gray-100
            }

            .dropdown-close {
                > .dropdown-item {
                    font-weight: $font-weight-bold;
                    color: $primary;
                    > .fa-long-arrow-left {
                        margin-top: -0.125rem;
                        margin-right: 0.5rem;
                    }
                }

            }

            .dropdown-menu {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .dropdown {
                position: static;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .navbar-expand-lg {
        .navbar-nav {
            .dropdown-menu {
                /* arrow - shadow */
                &::before {
                    border-bottom: 10px solid rgba($black, 0.05);
                    border-left: 10px solid rgba($black, 0);
                    border-right: 10px solid rgba($black, 0);
                    content: "";
                    display: inline-block;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    top: -10px;
                }

                /* arrow */
                &::after {
                    border-bottom: 9px solid $dropdown-bg;
                    border-left: 9px solid rgba($black, 0);
                    border-right: 9px solid rgba($black, 0);
                    content: "";
                    display: inline-block;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    top: -9px;
                }
            }
        }
    }

    .dropdown-multicol {
        min-width: 35rem;

        .dropdown-inner {
            display: flex;
            align-items: flex-start;
        }

        svg {
            margin-right: 0.75rem;
        }
    }

    .dropdown-content-item {
        padding: $dropdown-item-padding-y-lg $dropdown-item-padding-x-lg;
    }

    .user-login-header {
        .form-group:first-child {
            margin-bottom: 2px;
        }
    }
}

// Sticky Container
.sticky-element {
    position: relative;
    z-index: 2;

    .sticky-element-inner {
        overflow: hidden;
        padding: 0.5rem 0 0;

        .scrollable-tabs {
            margin-bottom: 1rem;
        }
    }

    &.is-sticky {
        box-shadow: $box-shadow;

        .sticky-element-inner {
            background-color: $white;
        }
    }
}

.mixitup-container {
    .sticky-element {
        background: $white;
        padding: 0.625rem;

        .sticky-element-inner {
            padding-left: 0.375rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .sticky-element {
        .sticky-element-inner {
            padding: 1.125rem 0 0;

            .scrollable-tabs {
                margin-bottom: 1.25rem;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .sticky-element {
        transform: none;
        backface-visibility: hidden;
        @include transition(transform 0.25s ease-in-out);

        &.is-sticky.heads-up {
            transform: translateY(-55px);
            transform: translate3d(0, -55px, 0);
        }
    }
}

// Download Form
.downloadform-icon {
    svg {
        max-width: 100%;
        height: auto;
    }
}

// Footer
.footer {
    font-size: $font-size-xs;
    letter-spacing: $font-spacing-xs;

    @include media-breakpoint-up(lg) {
        font-size: $font-size-sm;
    }

    @include media-breakpoint-down(md) {
        .text-style-primary {
            font-size: $font-size-xs;
        }

        .footer-logo {
            max-width: 87px;
            height: auto;
        }
    }

    &-content {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            padding-top: 5rem;
            padding-bottom: 3rem;
        }

        .list-unstyled li:not(:last-child){
            padding-bottom: 0.25rem;
        }
    }

    .footer-logos {
        padding-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            padding-bottom: 5rem;
        }

        a {
            display: block;
            padding: 1rem 0;
        }

        .footer-logo {
            width: 80px;
            height: auto;

            @include media-breakpoint-up(md) {
                width: 100%;
                max-width: 120px;
            }
        }


        .border-md-right {
            @include media-breakpoint-up(md) {
                border-right: 1px solid $gray-200;
            }
        }

        .border-xl-right {
            @include media-breakpoint-up(xl) {
                border-right: 1px solid $gray-200;
            }
        }
    }

    .swatch-light {
        .list-inline-item:not(:last-child) {
            margin-right: 1.25rem;

            @include media-breakpoint-up(lg) {
                margin-right: 2.5rem;
            }
        }


        .social-media-link {
            font-size: 1.375rem;
            color: $dark;

            &:hover {
                color: $primary;
            }
        }
    }

    .app-link {
        max-width: 130px;
    }

    .app-badge {
        width: 130px;
        max-width: 100%;
        height: auto;
    }
}

.enter-animation {
    animation: enter 800ms 200ms ease-out forwards;
}

.enter-animation-img {
    animation: enter 800ms 400ms ease-out forwards;
}

@keyframes enter {
    from {
        opacity: 0;
        transform: translate3d(0, 1em, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
