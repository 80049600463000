// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
/* in critical.scss */



// Row
//
// Rows contain your columns.

@if $enable-grid-classes {
    /* in critical.scss
    .row {
        @include make-row();
    }
    */

    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
    @include make-grid-columns();
}
