// Modal
@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$colors: map-remove($colors, "blue", "indigo", "purple", "pink", "red", "orange", "yellow", "teal", "cyan");
$colors: (
        "gray": $gray-400
);
$theme-colors: map-remove($theme-colors, "info", "warning");

@import "bootstrap/scss/modal";

/* Blur Effekt */
.modal-open {
    .navbar.fixed-top,
    #main,
    .footer {
        filter: blur(6px);
    }
}

.modal-dialog-md-centered {
    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

        // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
        &::before {
            display: block; // IE10
            height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
            height: min-content; // Reset height to 0 except on IE
            content: "";
        }
    }
}

.modal-header {
    padding-bottom: 0;

    .close {
        padding: 0;
        margin: 0 0 0 auto;
    }
}

.modal-footer {
    padding: $modal-inner-padding;
}

.modal-bottom {
    padding: $modal-inner-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-content {
    box-shadow: $modal-content-box-shadow-xs;
}

@include media-breakpoint-up(lg) {
    .modal-header {
        padding: $modal-header-padding-y-lg $modal-header-padding-x-lg 0;
    }

    .modal-body {
        padding: 0.875rem $modal-content-padding-lg 0.875rem;
    }

    .modal-bottom {
        padding: 2rem $modal-content-padding-lg $modal-content-padding-lg;
    }

}

.modal-facts {
    font-size: $font-size-xs;

    & > *:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        font-size: $font-size-sm;
    }
}

.modal-icons {
    svg {
        + svg {
            margin-left: 0.25rem;

            @include media-breakpoint-up(lg) {
                margin-left: 0.5rem;
            }
        }
    }
}

.wizard-modal {
    .swatch-dark {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        @include media-breakpoint-up(md) {
            position: relative;
            overflow: hidden;
            display: flex;

            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: 0;

            &::after {
                content: "";
                position: absolute;
                top: -64px;
                left: -80px;
                width: 420px;
                height: 420px;
                max-width: 100%;
                background-image: $globe-bg-green;
                background-repeat: no-repeat;
                background-size: contain;
                z-index: 0;
            }

            .modal-body {
                align-self: flex-end;
                z-index: 1;

                @include media-breakpoint-up(lg) {
                    padding-bottom: 3.5rem;
                }

                @include media-breakpoint-up(xl) {
                    padding-bottom: 5rem;
                }
            }
        }
    }

    .modal-xl {
        @include media-breakpoint-up(sm) {
            max-width: none;
            margin-left: 1rem;
            margin-right: 1rem;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
            max-width: $modal-xl;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-body {
        @include media-breakpoint-up(xl) {
            padding-top: 2rem;
            padding-left: 5rem;
            padding-right: 5rem;
        }
    }

    .modal-bottom {
        @include media-breakpoint-up(lg) {
            padding-bottom: 3.5rem;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 5rem;
        }
    }

    .tab-content {
        .tab-pane {
            display: block;
            height: 0;
            overflow-y: hidden;

            &.active {
                height: auto;
            }
        }
    }

    .modal-body-form {
        .tab-content {
            @include media-breakpoint-up(md){
                min-height: 395px;
            }

            @include media-breakpoint-up(lg){
                min-height: 440px;
            }

            @include media-breakpoint-up(xl){
                min-height: 422px;
            }
        }
    }

    .form-container {
        padding-right: $form-grid-gutter-width-xs;
        padding-left: $form-grid-gutter-width-xs;
    }

    .form-row {
        margin-right: -$form-grid-gutter-width-xs / 2;
        margin-left: -$form-grid-gutter-width-xs / 2;

        > .col,
        > [class*="col-"] {
            padding-right: $form-grid-gutter-width-xs / 2;
            padding-left: $form-grid-gutter-width-xs / 2;
        }
    }

    .custom-control.custom-control-block,
    .form-group {
        margin-bottom: $form-grid-gutter-width-xs;
    }

    .custom-control.font-xs {
        margin-top: 1rem;
    }

    textarea.form-control {
        resize: none;
    }

}

.wizard-tab-description {
    @include media-breakpoint-up(lg) {
       font-size: $font-size-sm;
    }
}
