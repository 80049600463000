/* cookieconsent styling */

.cc-window {
    position: fixed;
    overflow: hidden;
    opacity: 1;
    transition: opacity 1s ease;
    font-size: 1rem;
    z-index: 99999;
    background-color: $white;
    color: $body-color;
    padding: 1rem;
    border-radius: $border-radius;
    box-shadow: $modal-content-box-shadow-xs;

    /* Position Center */
    width: 100%;
    max-width: 460px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.cc-window.cc-invisible {
    opacity: 0;
}

.cc-revoke {
    display: none;
    visibility: hidden;
}

.cc-btn,
.cc-link,
.cc-revoke {
    cursor: pointer;
}

.cc-link {
    opacity: 0.8;
    display: inline-block;
    text-decoration: underline;

    &:hover {
        opacity: 1;
    }

    &:focus {
        outline: 1px dotted;
    }

    &:active,
    &:visited {
        color: initial;
    }
}

.cc-btn,
.db--overlay-submit {
    display: block;
    padding: 0.4rem 0.8rem;
    border-width: 1px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    color: $white;
    border-color: $primary;
    background-color: $primary;

    &:hover,
    &:focus {
        color: $white;
        background-color: darken($primary, 7%)!important;
        border-color: darken($primary, 7%);
        text-decoration: none;
    }

    &:focus {
        outline: 1px dotted;
    }
}

.cc-highlight {
    .cc-btn:first-child {
        background-color: transparent;
        border-color: transparent;
        text-decoration: underline;

        &:focus,
        &:hover {
            background-color: transparent!important;
            text-decoration: underline;
        }
    }

    .cc-btn:last-child {
        font-size: 1.25rem;
        font-weight: $font-weight-bold;
        border-radius: $btn-border-radius;
    }
}

.cc-message {
    display: block;
    padding: 0 0.5rem;
}


.cc-compliance {
    display: flex;
    flex-wrap: wrap;

    .cc-btn {
        flex: 0 0 100%;
        max-width: 100%;

        & + .cc-btn {
            margin-top: 0.5rem;
        }
    }

}

@media print {
    .cc-revoke,
    .cc-window {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .cc-btn {
        white-space: normal;
    }
}

@media screen and (max-width: 419px) and (orientation: portrait),
screen and (max-width: 767px) and (orientation: landscape) {

    .cc-window{
        top: unset;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateX(0);
        max-width: none;
    }
}

@media screen and (min-width: 768px) {
    .cc-window {
        top: 50%;
        bottom: unset;
        transform: translate(-50%, -50%);
        padding: 1.75rem;
    }
}

body.dp--cookie-consent {
    &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($dark, 0.4);
        z-index: 1090;
    }
}


.dp--cookie-check {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;

    label {
        padding: 5px;
        display: block;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

/*
.dp--iframe {
    display: none;

    &.dp--loaded {
        display: block;
    }
}

.dp--overlay {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $light;
    color: $dark;
    border: 2px solid $primary;
    border-radius: $border-radius;
}

.embed-responsive-16by9 {
    .dp--overlay {
        margin-top: -56.25%;
    }
}

.dp--overlay-header {
    font-weight: bold;
    font-size: 1rem;
}

.dp--overlay-description {
    margin-top: 0.5rem;
    font-size: 0.75rem;
}

.dp--overlay-button {
    margin-top: 0.5rem;
    font-size: 0.75rem;
}

.db--overlay-submit {
    font-size: 1rem;
    display: inline-block;
}

.dp--iframe.dp--loaded+.dp--overlay {
    display: none
}
*/
