@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$colors: map-remove($colors, "blue", "indigo", "purple", "pink", "red", "orange", "yellow", "teal", "cyan");
$colors: (
        "gray": $gray-400
);
$theme-colors: map-remove($theme-colors, "info", "warning");

@import "bootstrap/scss/breadcrumb";

.breadcrumb {
    color: $gray-300;
    line-height: 1;
}

.breadcrumb-item + .breadcrumb-item::before{
    display: flex;
    align-items: center;
    width: 1.125rem;
    height: auto;
    background-repeat: no-repeat;
    background-image: escape-svg($breadcrumb-divider-icon);
}

