@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$colors: map-remove($colors, "blue", "indigo", "purple", "pink", "red", "orange", "yellow", "teal", "cyan");
$colors: (
        "gray": $gray-400
);
$theme-colors: map-remove($theme-colors, "info", "warning");

//
// Base styles
//

.alert {
    position: relative;
    padding: $alert-padding-y $alert-padding-x;
    margin-bottom: $alert-margin-bottom;
    border: $alert-border-width solid transparent;
    @include font-size($small-font-size);
    @include border-radius($alert-border-radius);

    > p {
        margin-bottom: 0;
    }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

$alert-colors: (
    "success": $primary,
    "info": $dark,
    "warning": $gray-600,
    "danger": $danger,
);

@each $color, $value in $alert-colors {
    .alert-#{$color} {
        color: $value;
        background: $white;
        border-color: $value;

        .alert-link {
            color: $value;
        }
    }
}


.typo3-messages {
    @include list-unstyled();
}
