//
// Basic Bootstrap table
//

.table {
    width: 100%;
    margin-bottom: $spacer;
    color: $table-color;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.
    font-size: $font-size-sm;


    th,
    td {
        padding: $table-cell-padding;
        vertical-align: top;
        border-bottom: $table-border-width solid $table-border-color;
    }

    thead th {
        vertical-align: bottom;
        color: $table-head-color;
    }

    tbody + tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }

    .link-primary,
    .link-external,
    .link-primary-back {
        white-space: nowrap;
    }
}

// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table-bordered {
    border: $table-border-width solid $table-border-color;

    th,
    td {
        border: $table-border-width solid $table-border-color;
    }

    thead {
        th,
        td {
            border-bottom-width: 2 * $table-border-width;
        }
    }
}
