
//Case Study - Top

.case-study-top {
    margin-bottom: 1.5rem;
    .img-col {
        @include media-breakpoint-up(md) {
            z-index: 1;
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                top: -64px;
                right: -80px;
                width: 420px;
                height: 420px;
                max-width: 100%;
                background-image: $globe-bg-green;
                background-repeat: no-repeat;
                background-size: contain;
                z-index: 0;
            }
        }
    }

    .card-col {
        @include media-breakpoint-up(md) {
            z-index: 2;

            &.offset-md-n1 {
                margin-left: -8.33334%; //-(percentage(1/12)); -> little bit too less.. :/
            }
        }
    }
}

// Case Study - Detail Page
.case-study-lead {

    @include media-breakpoint-up(lg) {

        .case-study-card {
            .card-body {
                font-size: $font-size-sm;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .row.no-gutters {
            margin-left: -($grid-gutter-width);
            margin-right: -($grid-gutter-width);

            > .img-col{
                padding-right: $grid-gutter-width;
            }

            > .card-col {
                padding-left: $grid-gutter-width;
                padding-right: $grid-gutter-width;
            }
        }
    }


    .img-col {
        @include media-breakpoint-up(md) {
            z-index: 1;

            &.offset-md-n2 {
                margin-left: -(percentage(2/12));
            }
        }
    }

    .card-col {

        @include media-breakpoint-down(sm) {
            .case-study-card {
                margin-top: -2rem;
                margin-right: percentage(2/12);
            }
        }

        @include media-breakpoint-down(xs) {
            .case-study-card {
                margin-right: percentage(1/12);
            }
        }

        @include media-breakpoint-up(md) {
            z-index: 2;

            .case-study-card {
                margin-top: 3.25rem;
            }
        }

        @include media-breakpoint-up(lg) {
            .case-study-card {
                margin-top: 6.25rem;
            }
        }

        @include media-breakpoint-up(xl) {
            .case-study-card {
                margin-top: 8.75rem;
            }
        }
    }
}
