// CTA Element

.frame-type-wvier_cta {
    overflow-x: hidden;

    .title-lg,
    .title-sm,
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4{
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 2.5rem;
        }
    }

    .col-dark,
    .col-primary {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -16px;
            right: -16px;
            z-index: -1;

            @include media-breakpoint-up(sm) {
                width: 100vw;
            }
        }
    }

    .col-dark {
        &::after {
            background: $gray-gradient;

            @include media-breakpoint-up(sm) {
                left: auto;
                right: 0;
            }
        }
    }

    .col-primary {
        &::after {
            background-color: $primary;

            @include media-breakpoint-up(sm) {
                left: 0;
                right: auto;
            }
        }
    }
}

.cta-text {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;

    color: $white;

    @include media-breakpoint-between(sm, sm) {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }

    @include media-breakpoint-up(md) {
        max-width: percentage(5 / 6);
    }

    @include media-breakpoint-up(xl) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

.cta-img {
    padding-top: 2.265rem;
    padding-bottom: 2.265rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-between(sm, sm) {
        padding-left: $grid-gutter-width;
    }

    @include media-breakpoint-up(sm) {
        height: 100%;

        &.cta-img-bottom {
            align-items: flex-end;
        }
    }

    @include media-breakpoint-up(md) {
        max-width: percentage(5 / 6);
        margin-left: auto;
        margin-right: 0;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    &.bgimage-globe {
        background-image: $globe-bg-darkgray;
        background-repeat: no-repeat;
        background-position: center;

        @include media-breakpoint-up(md) {
            background-position: left center;
        }
    }
}
