/* Splide Core Files */
@import '@splidejs/splide/src/css/core/foundation/animations';

@import '@splidejs/splide/src/css/core/object/objects/root';
@import '@splidejs/splide/src/css/core/object/objects/container';
@import '@splidejs/splide/src/css/core/object/objects/list';
@import '@splidejs/splide/src/css/core/object/objects/pagination';
@import '@splidejs/splide/src/css/core/object/objects/slide';
@import '@splidejs/splide/src/css/core/object/objects/slider';
@import '@splidejs/splide/src/css/core/object/objects/track';

@import '@splidejs/splide/src/css/core/object/modifiers/draggable';
@import '@splidejs/splide/src/css/core/object/modifiers/fade';

@import '@splidejs/splide/src/css/themes/default/index';

.frame-type-wvier_imageslider {
    .frame-header {
        @include media-breakpoint-up(lg) {
            margin-bottom: 2.5rem;
        }
    }
}

.splide {
    &__arrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 0;
        padding: 0;
        background: transparent;
        color: $primary;
        transition: color 0.25s ease-in-out;
        position: inherit;
        transform: none;

        svg {
            width: inherit;
            height: inherit;
        }

        &.splide__arrow--prev {
            svg {
                transform: none;
            }
        }

        &:disabled {
            color: $gray-300;
        }

        &:hover {
            cursor: pointer;
            &:not(:disabled) {
                color: $btn-focus-color;
            }
        }

        &:focus {
            outline: none;

            &:not(:disabled) {
                color: $btn-focus-color;
            }
        }
    }
}

.teaser-splide {
    .teaser-link {
        position: relative;
        display: block;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: $globe-bg-green;
            background-repeat: no-repeat;
            background-size: contain;
            background-position-x: 95px;
            background-position-y: 1rem;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                background-position-y: 2rem;
            }
        }
        img {
            width: 100%;
            filter: grayscale(100%);
            transition: 0.25s ease-in-out;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
            img {
                filter: grayscale(0%);
            }
        }
    }

    .image {
        position: relative;

        .figure-caption {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0 1rem 1rem;
            color: $white;
            font-family: "Soehne Breit", sans-serif;
            font-size: 1.375rem;
            line-height: 1.05;
            z-index: 2;
            word-break: break-word;

            @include media-breakpoint-up(lg) {
                padding: 0 2rem 2.625rem;
                font-size: 1.5rem;
            }
        }
    }
}

.splide {
    &__pagination {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 50%;
        transform: translateX( -50% );
        padding: 0;

        &__page {
            display: inline-block;
            width: $splide-dot-size;
            height: $splide-dot-size;
            background: $splide-dot-color;
            border-radius: 50%;
            margin: 2px 8px;
            padding: 0;
            transition: background-color 0.25s linear;
            border: 0;

            &.is-active {
                background: $splide-dot-active-color;
            }

            &:hover {
                cursor: pointer;
                background: $splide-dot-focus-color;
            }

            &:focus {
                outline: none;
                background: $splide-dot-focus-color;
            }
        }
    }
}

.splide {
    &-dots {
        .splide {
            &__track {
                padding-bottom: 2.375rem;

                @include media-breakpoint-up(lg) {
                    padding-bottom: 1rem;
                }
            }
        }
    }
}


